<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header d-flex justify-content-between">
                        <div class="header-title">
                            <h4 class="card-title">Privacy Setting</h4>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="acc-privacy">
                            <div class="data-privacy">
                                <h4 class="mb-2">Account Privacy</h4>
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="customCheck5">
                                    <label class="form-check-label pl-2" for="customCheck5">Private Account</label>
                                </div>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                    has been the industry's standard dummy text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled it to make a type specimen book</p>
                            </div>
                            <hr>
                            <div class="data-privacy">
                                <h4 class="mb-2">Activity Status</h4>
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="activety">
                                    <label class="form-check-label pl-2" for="activety">Show Activity Status</label>
                                </div>
                                <p>It is a long established fact that a reader will be distracted by the readable content of
                                    a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                                    more-or-less normal distribution of letters, as opposed to using 'Content here, content
                                    here', making it look like readable English.</p>
                            </div>
                            <hr>
                            <div class="data-privacy">
                                <h4 class="mb-2"> Story Sharing </h4>
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="story">
                                    <label class="form-check-label pl-2" for="story">Allow Sharing</label>
                                </div>
                                <p>It is a long established fact that a reader will be distracted by the readable content of
                                    a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                                    more-or-less normal distribution of letters, as opposed to using 'Content here, content
                                    here', making it look like readable English.</p>
                            </div>
                            <hr>
                            <div class="data-privacy">
                                <h4 class="mb-2"> Photo Of You </h4>
                                <div class="form-check">
                                    <input type="radio" class="form-check-input" name="customRadio0" id="automatically" checked="">
                                    <label for="automatically" class="form-check-label pl-2">Add Automatically</label>
                                </div>
                                <div class="form-check">
                                    <input type="radio" class="form-check-input" name="customRadio0" id="manualy">
                                    <label for="manualy" class="form-check-label pl-2">Add Manualy</label>
                                </div>
                                <p>It is a long established fact that a reader will be distracted by the readable content of
                                    a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                                    more-or-less normal distribution of letters, as opposed to using 'Content here, content
                                    here', making it look like readable English.</p>
                            </div>
                            <hr>
                            <div class="data-privacy">
                                <h4 class="mb-2"> Your Profile </h4>
                                <div class="form-check">
                                    <input type="radio" class="form-check-input" name="customRadio1" id="public">
                                    <label for="public" class="form-check-label pl-2">Public</label>
                                </div>
                                <div class="form-check">
                                    <input type="radio" class="form-check-input" name="customRadio1" id="friend">
                                    <label for="friend" class="form-check-label pl-2">Friend</label>
                                </div>
                                <div class="form-check">
                                    <input type="radio" class="form-check-input" name="customRadio1" id="spfriend">
                                    <label for="spfriend" class="form-check-label pl-2">Specific Friend</label>
                                </div>
                                <div class="form-check">
                                    <input type="radio" class="form-check-input" name="customRadio1" id="onlyme">
                                    <label for="onlyme" class="form-check-label pl-2">Only Me</label>
                                </div>
                                <p>It is a long established fact that a reader will be distracted by the readable content of
                                    a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                                    more-or-less normal distribution of letters, as opposed to using 'Content here, content
                                    here', making it look like readable English.</p>
                            </div>
                            <hr>
                            <div class="data-privacy">
                                <h4 class="mb-2"> Login Notification </h4>
                                <div class="form-check">
                                    <input type="radio" class="form-check-input" name="customRadio2" id="enable">
                                    <label for="enable" class="form-check-label pl-2">Enable</label>
                                </div>
                                <div class="form-check">
                                    <input type="radio" class="form-check-input" name="customRadio2" id="disable">
                                    <label for="disable" class="form-check-label pl-2">Disable</label>
                                </div>
                                <p>It is a long established fact that a reader will be distracted by the readable content of
                                    a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                                    more-or-less normal distribution of letters, as opposed to using 'Content here, content
                                    here', making it look like readable English.</p>
                            </div>
                            <hr>
                            <div class="data-privacy">
                                <h4 class="mb-2">Privacy Help</h4>
                                <a href="#"><i class="ri-customer-service-2-line mr-2"></i>Support</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'UserPrivacySetting'
}
</script>
